.card {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    background-color: #27A5EF;
    width: 30rem;
    height: 50;
    padding: 0 2rem 2rem 2rem;
    border-radius: 10px;
  }


  
  @media screen and (max-width: 768px) {
    .card {
      width: 20rem;
    }
    
  }

  @media screen and (max-width: 500px) {
    .card {
      width: 14rem;
    }
    
  }
  
  .card img {
    margin-top: -20%;
    border-radius: 20px;
  }
  
  .card h1 {
    margin: 0;
    margin-top: 1rem;
    font-family: "Michroma", sans-serif;
  }
  
  .card p {
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  
 