@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


.App {
  text-align: center;
}

a,button{
  cursor: none;
}




.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.App-268268{
  /* display: flex;
  justify-content: center;
  align-items: flex-start; */
  height: 100vh;
  position: relative;
}

.carousel{
  /* width: 75vw !important; */
  height: 75vh !important;
}

.carousel__slide{
  border: none !important;
  /* scale: 3.5; */
  width: 30vw !important;
}
.carousel__slide-overlay:hover{
  display: none !important;
}

.carousel_slide:hover .carousel_slide-overlay {
  opacity: 0;
}

