@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Changa+One&display=swap');
body {
    margin: 0;
    padding: 0;
    background-color: #000000;
   background-repeat: no-repeat, repeat;
    background-size: cover;
    cursor: none ;
    
}

body {
  background-color: #000;
  position: relative;
  margin: 0;
  padding: 0;
}


.star {
  position: absolute;
  width: 2px;
  height: 2px;
  background: white;
  border-radius: 50%;
  animation: twinkling 10s infinite;
  z-index: -1;
}

@keyframes twinkling {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}


/* Styles for the comets */
.comet {
  position: absolute;
  animation: rotate 30s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



html {
  scroll-behavior: smooth;
  margin: 0px !important;
  padding: 0px !important;
  width: 100%;
  height: 100%;
}
body{
    overflow-x: hidden;
    overflow-y:auto;
}
.white{
  color: white !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (max-width: 768px){
  body{
    margin: 0;
    padding: 0;
    background-color: #000000;
    background-repeat: no-repeat, repeat;
    background-size: cover;
    overflow-x: hidden;
    overflow-y:auto;
}
html {
  scroll-behavior: smooth;
  margin: 0px !important;
  padding: 0px !important;
  width: 100%;
  height: 100%;
      overflow-x: hidden;


}
.mobile{
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
}
}

.heading {
  font-size: clamp(10px, 7vw, 90px);
}

.heading1 {
  color: white;
}

.heading2 {
  color: #27A5EF;
}
.caption {
  font-family: Inter;
  font-size: clamp(30px, 5vw, 50px);
  font-style: normal;
  font-weight: 700;
  text-align: center;
}

.small-caption {
  font-family: Inter;
  font-size: clamp(15px, 2vw, 40px);
  font-style: normal;
  font-weight: 700;
  text-align: center;
}



.heading {
  font-size: clamp(10px, 7vw, 90px);
}

.heading1 {
  color: white;
}

.heading2{
  color: #27a5ef;
}

.sp{
  color: #27a5ef;
}

.caro {
  border-style: solid;
  border-width: 8px;
  border-color: #21539e;
  border-radius: 40px;
  height: 20rem;
  width: 31rem;
}

.caption {
  font-family: Inter;
  font-size: clamp(30px, 5vw, 50px);
  font-style: normal;
  font-weight: 700;
  text-align: center;
}

.small-caption {
  font-family: Inter;
  font-size: clamp(15px, 2vw, 40px);
  font-style: normal;
  font-weight: 700;
  text-align: center;
}


@media (min-width: 780px) {
  .Mainapp{
    background : url("../public/images/finalimage.webp");
    background-repeat: no-repeat, repeat;
    background-size: contain;
    background-position: right;
    width: 100%;
    height: 100%;
      
}
}


.shadow-white {
    box-shadow: 4px 4px 8px rgba(255, 255, 255, 0.2);
  }
.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Apply to all scrollbars in the document */
::-webkit-scrollbar {
  width: 1px; /* Set the width of the scrollbar */
}

.track-card {
  transition: transform 0.3s ease, scale 0.3s ease;
}

.track-card:hover {
  transform: rotate(5deg);
  scale: 1.1;
}

.custom-cursor {
  position: fixed;
  pointer-events: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: none;
}

/* Hide the custom cursor on mobile screens */
@media (max-width: 768px) {
  .custom-cursor {
    display: none;
  }
  body{
    cursor: default;
  }
  .schedule-text {
    font-size: 10px;
  }
}


.schedule-text{
  font-family: 'Changa One', cursive;
  font-size: 20px;
  color: #ffffff;
  text-align:left;
}
abc{
  margin-top: 0;
}
@media  ( (min-width: 410px) and (max-width: 768px)) {
  .abc{
    margin-top: -24rem;
  }
  
}